<template>
  <section class="l-content">
    <el-card class="l-condition" name="hTable">
      <el-form :inline="true" :model="condition">
        <el-form-item label="登记时间">
          <l-datepicker type="daterange" :default="[condition.startDate, condition.endDate]" @change="dataRangeChange"></l-datepicker>
        </el-form-item>
        <el-form-item>
          <el-input v-model="condition.keyword" size="small" width="300" placeholder="受访人名称"> </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" size="small" @click="getPageData()">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button type="warning" size="small" @click="doExport()">导出</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <el-divider> </el-divider>
    <el-card v-if="pageData">
      <l-table :option="tableOption" :data="pageData.list" :start-index="pageData.startIndex">
        <template slot="vistorName" slot-scope="scope">
          <el-button type="text" @click="$site.view_visitor_detail(scope.row.visitId)">{{ scope.row.vistorName }}</el-button>
        </template>
        <template slot="visitStatusName" slot-scope="scope">
          <span v-if="scope.row.visitStatus == 0" class="text-grey">未提交</span>
          <span v-else-if="scope.row.visitStatus == 1" class="text-blue">已提交</span>
          <span v-else-if="scope.row.visitStatus == 10" class="text-green">已入围</span>
          <span v-else-if="scope.row.visitStatus == 9" class="text-red">无效建议</span>
        </template>
        <template slot="effectStatusName" slot-scope="scope">
          <span v-if="scope.row.effectStatus == 0" class="text-grey">---</span>
          <span v-else-if="scope.row.effectStatus == 1" class="text-blue">实施中</span>
          <span v-else-if="scope.row.effectStatus == 2" class="text-green">已完成</span>
          <span v-else-if="scope.row.effectStatus == 8" class="text-red">无法实施</span>
          <span v-else-if="scope.row.effectStatus == 9" class="text-red">暂缓实施</span>
        </template>
      </l-table>
      <l-pager :page="pageData" @change="getPageData"></l-pager>
    </el-card>
  </section>
</template>

<script>
import { mapState } from 'vuex'
export default {
  computed: {
    ...mapState(['userInfo'])
  },
  name: 'AdviceList',
  data() {
    return {
      tableOption: {
        stripe: true,
        columns: [
          { label: '序号', type: 'index' },
          { label: '访客姓名', slot: 'vistorName', overflow: true },
          { label: '访客手机号', prop: 'visitorMobile', overflow: true },
          { label: '访客单位', prop: 'vistorUnitName', overflow: true },
          { label: '受访人', prop: 'userName' },
          { label: '拜访日期', prop: 'visitDate', filter: (a, data) => this.$lgh.limitTo(a, 10) + ' | ' + data.visitDurning },
          { label: '拜访事由', prop: 'visitReason' },
          { label: '测量体温', prop: 'temperature' }
        ]
      },
      condition: {
        // enterpriseCode: this.userInfo.enterpriseCode,
        departId: '',
        startDate: '',
        endDate: '',
        keyword: '',
        pageIndex: 1,
        pageSize: 10
      },
      pageData: null
    }
  },
  created() {
    this.getPageData()
  },
  methods: {
    dataRangeChange(d1, d2) {
      this.condition.startDate = d1
      this.condition.endDate = d2
    },
    async getPageData(pageOption) {
      Object.assign(this.condition, pageOption)
      this.pageData = await this.$lgh.get('api/enterprise/visit/pagedlist', ['enterpriseCode', this.condition])
    },
    doExport() {
      const h = this.$createElement

      this.$lgh.get('api/enterprise/visit/export', ['enterpriseCode', this.condition]).then((res) => {
        this.$lgh.msgSuccess(h('a', { attrs: { target: '_blank', href: res.link_url, download: '访客记录导出' + new Date().valueOf() + '.xlsx' } }, '点击下载'))
      })
    }
  }
}
</script>
